export const globalShowInfosEng = [
  {
    id: 1001,
    name: "FNC BAND KINGDOM 2024 - Japan",
    address:
      "Makuhari Event Hall 2 Chome Nakase, Mihama Ward, Chiba, 261-0023, Japan",
    note: [""],
    capacity: "Maximum capacity of 9,000 people",
    seats: [""],
    setlist: [
      "Kick-Ass",
      "Songbird",
      "Star",
      "Endless Summer",
      "옥탑방 (Rooftop)",
      "Stand by me",
      "[DAY1] I'm Sorry - Hweseung with CNBLUE",
      "[DAY2] 바래 (I Wish) - Seunghyub with FTISLAND",
    ],
    ootd: [
      "/image/show_detail/ootd/world/240713_KINGDOM_DAY1.jpeg",
      "/image/show_detail/ootd/world/240714_KINGDOM_DAY2.jpeg",
    ],
  },
  {
    id: 1002,
    name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN - Yokohama",
    address:
      "T Zepp Yokohama 4 Chome-3-6 Minatomirai, Nishi Ward, Yokohama, Kanagawa, 220-0012 Japan",
    note: [""],
    capacity:
      "1st floor: Maximum 1,630 people (standing) / 2nd floor: Approximately 516 people (fixed seating)",
    seats: ["/image/show_detail/seats/world/241029_KT_Zepp_YOKOHAMA_SEAT.png"],
    setlist: [
      "불놀이 (Flowerwork)",
      "Video Therapy",
      "Born to be - Unreleased",
      "Star",
      "꽃바람 (YOUTH)",
      "아 진짜요. (Oh really.)",
      "Blue Moon",
      "옥탑방 (Rooftop)",
      "그랬나봐 (I Think I Did)",
      "FLOWER FANTASY",
      "Love You Like That - Unreleased",
      "굿밤 (GOOD BAM)",
      "ANYWAY",
      "4242",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Kick-Ass",
      "Lupin",
      "Songbird",
      "Moonshot",
      "Monster",
      "진짜가 나타났다 (The Real)",
      "Run",
      "The World Is Mine",
      "그러니까 우리 (Don't Forget This)",
      "Stand By Me",
      "Sunset",
      "폭망 (I Like You)",
      "놔 (Leave It)",
      "Blue Moon",
    ],
    ootd: [
      "/image/show_detail/ootd/world/241029_HIDE-OUT_JAPAN_OOTD_01.jpeg",
      "/image/show_detail/ootd/world/241029_HIDE-OUT_JAPAN_OOTD_02.jpeg",
      "/image/show_detail/ootd/world/241029_HIDE-OUT_JAPAN_OOTD_03.jpeg",
    ],
  },
  {
    id: 1003,
    name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN - Nagoya",
    address:
      "THE BOTTOM LINE 4-7-11 Imaike, Chikusa Ward, Nagoya, Aichi 464-0850, Japan",
    note: ["Seung Hyub's birthday"],
    capacity: "Approximately 750 people",
    seats: [
      "/image/show_detail/seats/world/241031_NAGOYA BOTTOM LINE_MAP1.png",
      "/image/show_detail/seats/world/241031_NAGOYA BOTTOM LINE_MAP2.png",
    ],
    setlist: [
      "불놀이 (Flowerwork)",
      "Video Therapy",
      "Born to be - Unreleased",
      "Kick-Ass",
      "Lupin",
      "Songbird",
      "Blue Moon",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "옥탑방 (Rooftop)",
      "그랬나봐 (I Think I Did)",
      "FLOWER FANTASY",
      "Love You Like That - Unreleased",
      "굿밤 (GOOD BAM)",
      "ANYWAY",
      "4242",
      "Star",
      "꽃바람 (YOUTH)",
      "아 진짜요. (Oh really.)",
      "Moonshot",
      "Monster",
      "진짜가 나타났다 (The Real)",
      "Run",
      "The World Is Mine",
      "그러니까 우리 (Don't Forget This)",
      "Stand By Me",
      "Sunset",
      "폭망 (I Like You)",
      "놔 (Leave It)",
      "Blue Moon",
    ],
    ootd: [
      "/image/show_detail/ootd/world/241031_HIDE-OUT_JAPAN_OOTD_00.jpeg",
      "/image/show_detail/ootd/world/241031_HIDE-OUT_JAPAN_OOTD_01.jpeg",
      "/image/show_detail/ootd/world/241031_HIDE-OUT_JAPAN_OOTD_02.jpeg",
    ],
  },
  {
    id: 1004,
    name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN - Osaka",
    address:
      "Zepp Namba 2 Chome-1-39 Shikitsuhigashi, Naniwa Ward, Osaka, 556-0012 Japan",
    note: [""],
    capacity:
      "Maximum 2,530 people - 1st floor 2,090 people (standing) / 2nd floor 440 people (seated)",
    seats: [
      "/image/show_detail/seats/world/241101_ZEPP NAMBA_SEAT_1F.png",
      "/image/show_detail/seats/world/241101_ZEPP NAMBA_SEAT_2F.png",
    ],
    setlist: [
      "불놀이 (Flowerwork)",
      "Video Therapy",
      "Born to be - Unreleased",
      "Kick-Ass",
      "Lupin",
      "Songbird",
      "Blue Moon",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "옥탑방 (Rooftop)",
      "그랬나봐 (I Think I Did)",
      "FLOWER FANTASY",
      "Love You Like That - Unreleased",
      "굿밤 (GOOD BAM)",
      "ANYWAY",
      "4242",
      "Star",
      "꽃바람 (YOUTH)",
      "아 진짜요. (Oh really.)",
      "Moonshot",
      "Monster",
      "진짜가 나타났다 (The Real)",
      "Sunset",
      "폭망 (I Like You)",
      "놔 (Leave It)",
      "Run",
      "The World Is Mine",
      "그러니까 우리 (Don't Forget This)",
      "Stand By Me",
      "Blue Moon",
      "Songbird",
      "Kick-Ass",
    ],
    ootd: [
      "/image/show_detail/ootd/world/241101_HIDE-OUT_JAPAN_OOTD_01.jpeg",
      "/image/show_detail/ootd/world/241101_HIDE-OUT_JAPAN_OOTD_02.jpeg",
      "/image/show_detail/ootd/world/241101_HIDE-OUT_JAPAN_OOTD_03.jpeg",
    ],
  },
  {
    id: 1005,
    name: "2024 N.Flying LIVE HIDE-OUT in ASIA - Bangkok",
    address:
      "MCC HALL BANGKAE, 362 Kanchanaphisek Road, Bang Khae Nuea, Bang Khae, Bangkok 10160, Thailand",
    note: [""],
    capacity: "Approximately 2,000 people",
    seats: ["/image/show_detail/seats/world/241123_HIDE-OUT_BANGKOK_SEAT.jpg"],
    setlist: [
      "불놀이 (Flowerwork)",
      "Video Therapy",
      "Born to be - Unreleased",
      "Star",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "꽃바람 (YOUTH)",
      "아 진짜요. (Oh really.)",
      "Blue Moon",
      "옥탑방 (Rooftop)",
      "그랬나봐 (I Think I Did)",
      "FLOWER FANTASY",
      "Love You Like That - Unreleased",
      "굿밤 (GOOD BAM)",
      "ANYWAY",
      "4242",
      "Autumn Dream",
      "기가 막혀 (Awesome)",
      "Moonshot",
      "Monster",
      "진짜가 나타났다 (The Real)",
      "Run",
      "The World Is Mine - Encore",
      "그러니까 우리 (Don't Forget This) - Encore",
      "Stand By Me - Encore",
    ],
    ootd: [
      "/image/show_detail/ootd/world/241123_HIDE-OUT_BANGKOK_OOTD_01.jpeg",
      "/image/show_detail/ootd/world/241123_HIDE-OUT_BANGKOK_OOTD_02.jpeg",
    ],
  },
  {
    id: 1006,
    name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA - Kuala Lumpur",
    address:
      "Zepp Kuala Lumpur BBCC No.2, Jalan Hang Tuah, Bukit Bintang City Centre, 55100 Kuala Lumpur",
    note: [
      "/image/show_detail/note/2024/ZEPP_KL_GUIDE_00.jpeg",
      "/image/show_detail/note/2024/ZEPP_KL_GUIDE_01.jpeg",
      "/image/show_detail/note/2024/ZEPP_KL_GUIDE_02.jpeg",
      "/image/show_detail/note/2024/ZEPP_KL_GUIDE_03.jpeg",
    ],
    capacity: "Approximately 2,500 people",
    seats: ["/image/show_detail/seats/world/241201_HIDE-OUT_MY_SEAT.png"],
    setlist: [
      "불놀이 (Flowerwork)",
      "Video Therapy",
      "Born to be - Unreleased",
      "Star",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "꽃바람 (YOUTH)",
      "아 진짜요. (Oh really.)",
      "Blue Moon",
      "옥탑방 (Rooftop)",
      "그랬나봐 (I Think I Did)",
      "FLOWER FANTASY",
      "Love You Like That - Unreleased",
      "굿밤 (GOOD BAM)",
      "ANYWAY",
      "4242",
      "Autumn Dream",
      "기가 막혀 (Awesome)",
      "기가 막혀 (Awesome) - Once more",
      "Moonshot",
      "Monster",
      "진짜가 나타났다 (The Real)",
      "진짜가 나타났다 (The Real) - Once more",
      "Run",
      "The World Is Mine - Encore",
      "폭망 (I Like You) - Encore",
      "그러니까 우리 (Don't Forget This) - Encore",
      "Stand By Me - Encore",
      "Sunset - Double Encore",
      "놔 (Leave It) - Double Encore",
      "Songbird - Double Encore",
    ],
    ootd: [
      "/image/show_detail/ootd/world/241201_HIDE-OUT_KUALALUMPUR_OOTD_00.jpeg",
    ],
  },
  {
    id: 1007,
    name: "FNC KINGDOM - SING SING SING - Japan",
    address:
      "Makuhari Messe International Exhibition Halls 9-11 2-1 Nakase, Mihama-ku, Chiba 261-8550, Japan",
    note: [""],
    capacity: "Approximately 12,000 people",
    seats: [""],
    setlist: [
      "Gunjou (YOASOBI) - Hweseung & Taemin",
      "Wind (FTISLAND) - Seunghyub & Jung Hae In",
      "Garasu no Shounen (KinKi Kids) - Hun & Lee Jung shin",
      "The World Is Mine",
      "Songbird",
      "Stand By Me",
      "Flashback",
      "옥탑방 (Rooftop)"
    ],
    ootd: [
        "/image/show_detail/ootd/world/241214_BAND_KINGDOM_SING_OOTD_01.jpeg",
        "/image/show_detail/ootd/world/241215_BAND_KINGDOM_SING_OOTD_01.jpeg"
    ],
  },
  {
    id: 1008,
    name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA - Macau",
    address: "Broadway Theatre, Broadway Macau, Estrada do Istmo, Cotai, Macau",
    note: [""],
    capacity: "Approximately 2,500 people",
    seats: ["/image/show_detail/seats/world/241222_HIDE-OUT_MACAU_SEAT.jpg"],
    setlist: ["Please let me know the setlist!"], // todo 셋리 못 찾음
    ootd: [
      "/image/show_detail/ootd/world/241222_HIDE-OUT_MACAU_01.jpeg",
      "/image/show_detail/ootd/world/241222_HIDE-OUT_MACAU_02.jpeg",
    ],
  },
  {
    id: 1009,
    name: "1st FNC BAND KINGDOM in TAOYUAN - Taiwan",
    address:
      "NTaoyuan Arena, No. 1, Section 1, Sanmin Road, Taoyuan District, Taoyuan City, Taiwan 330",
    note: [""],
    capacity: "Approximately 15,000 people",
    seats: [
      "/image/show_detail/seats/world/250104_THE TAOYUAN ARENA_SEAT_SUB_STAGE.jpg",
    ],
    setlist: [
      "Blue Moon",
      "Songbird",
      "Star",
      "Sunset",
      "옥탑방 (Rooftop)",
      "Stand By Me",
      "Flashback",
      "[DAY1] I'm Sorry - 회승 with 씨엔블루",
      "[DAY2] 바래 (I Wish) - Seunghyub with FTISLAND",
    ],
    ootd: [
      "/image/show_detail/ootd/world/250104_1st_KINGDOM_TAOYUAN_DAY1_OOTD1.jpeg",
      "/image/show_detail/ootd/world/250104_1st_KINGDOM_TAOYUAN_DAY2_OOTD.jpeg",
    ],
  },
  {
    id: 1010,
    name: "2025 N.Flying LIVE 'HIDE-OUT' in ASIA - TAIPEI",
    address:
      "TICC No. 1, Section 5, Xinyi Rd, Xinyi District, Taipei City, TAIPEI 110",
    note: ["/image/show_detail/note/2025/250209_TAIPEI_BENEFIT.jpg"],
    capacity: "Approximately 3,000 people",
    seats: ["/image/show_detail/seats/world/250209_HIDE-OUT_TAIPEI_SEAT.jpeg"],
    setlist: [""],
    ootd: [""],
  },
  // {
  //   id: 1011,
  //   name: "2025 N.Flying LIVE 'HIDE-OUT' in ASIA - MANILA",
  //   address: "NEW FRONTIER THEATER, 7 General Malvar Ave, Cubao, Quezon City, 1109 Metro Manila, Philippines",
  //   note: ["/image/show_detail/note/2025/250126_MANILA_BENEFIT.jpg"],
  //   capacity: "2,385 Seats",
  //   seats: [
  //       "/image/show_detail/seats/world/250126_MANILA_NEW_FRONTIER_THEATER.jpg"
  //   ],
  //   setlist: [""],
  //   ootd: [""],
  // },
];
